<template>
  <div class="jaky-tabs">
    <div
      v-for="option in options"
      :key="option.value"
      :class="{
        'jaky-tabs-item': true,
        'is-active': value === option.value
      }"
      @click="updateValue(option.value)"
    >
      <span class="jaky-tabs-item-text">{{ option.label }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array, // {value: string, label: string}[]
      default() {
        return []
      },
    },
    value: {
      type: String,
      default() {
        return 'today'
      },
    },
  },
  methods: {
    updateValue(newValue) {
      this.$emit('input', newValue)
    },
  },
}
</script>
